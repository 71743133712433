<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Form
            :form="form" @changeForm="(val) => form = val"
            @saveForm="save"
            @deleteRecord="deleteRecord"
            @actionLock="actionLock"
            @initialPassword="initialPassword"
        ></Form>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import { SYSTEM_USER_MODEL } from "@/constants";
import Form from "@/components/SystemUser/Form";
import { STATUS_SUCCESS_CODE } from "@/common/constants";
const SystemUserRepository = RepositoryFactory.get(SYSTEM_USER_MODEL);
export default {
    components: {
        Form
    },
    data() {
        return {
            form: {}
        }
    },
    async mounted() {
        const system_user = await SystemUserRepository.edit(this.$route.params.id);
        this.form = system_user.data.data
        if (this.form.user_sys_auth_div.length > 0) {
            this.form.user_sys_auth_div = this.form.user_sys_auth_div.split(",");
        } else {
            this.form.user_sys_auth_div = []
        }
    },
    methods: {
        async save() {
            this.form.user_sys_auth_div = this.form.user_sys_auth_div.toString()
            const system_user = await SystemUserRepository.update(this.form);
            if (system_user.status == STATUS_SUCCESS_CODE) {
                this.$toast.success(system_user.data.message, {})
                this.$router.push({ name: "list" })
            } else {
                this.$toast.error(system_user.error.message, {})
            }
        },
        async deleteRecord() {
            const system_user = await SystemUserRepository.delete(this.form.id);
            if (system_user.status == STATUS_SUCCESS_CODE) {
                this.$toast.success(system_user.data.message, {})
                this.$router.push({ name: "list" })
            }
        },
        async actionLock() {
            let data = {
                id: this.form.id,
                login_fail_count: 0,
                account_lock_flag: false
            }
            const system_user = await SystemUserRepository.actionLock(data);
            if (system_user.status == STATUS_SUCCESS_CODE) {
                this.form.account_lock_flag = false
                this.form.login_fail_count = 0
                this.$toast.success(system_user.data.message, {})
            }
        },
        async initialPassword() {
            if (!this.form.account_lock_flag) {
                let data = {
                    id: this.form.id
                }
                const system_user = await SystemUserRepository.initialPassword(data);
                if (system_user.status == STATUS_SUCCESS_CODE) {
                    this.$toast.success(system_user.data.message, {})
                }
            }
        }
    }
}
</script>